import React from 'react';
import format from 'date-fns/format';
import { Heading } from '@chakra-ui/react';
import { Small } from '../styles/HeadingStyles';

type Props = {
  mb?: string;
  /** Accepts a date format for the blog by line.   */
  date: Date;
  /** Authors First Name and Last Name  */
  name?: string;
  /** Info regarding category  */
  fontSize?: string;
  mt?: string;
};

/** Component for adding a by line with date and name to a blog post */
const BlogByLine = ({ date, name, ...props }: Props): JSX.Element => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as const;

  return (
    <Small {...props}>
      {new Intl.DateTimeFormat('en-GB', options).format(new Date(date))}

      {name && <strong> &#183; {name}</strong>}
    </Small>
  );
};

export default BlogByLine;
