import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql, Link } from 'gatsby';
import { Box, chakra, Container } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SubHeading } from '../components/styles/HeadingStyles';
import Header from '../components/ui/Header';
import Section from '../components/ui/Section';
import Containers from '../components/ui/Containers';
import BlogByLine from '../components/ui/BlogByLine';
import Cta from '../components/ui/Cta';
import AdminBar from '../components/ui/AdminBar';
import { adjustVideoSize } from '../lib/Utilties';

const BlogHomeLink = chakra(Link, {
  baseStyle: {},
});

BlogHomeLink.defaultProps = {
  color: 'primary',
  fontSize: 1,
};

const Body = chakra(Box, {
  baseStyle: {
    a: {
      color: 'primary',
      textDecorationColor: 'primary',
      textDecoration: 'underline',
    },
    h1: {
      variant: 'H1',
    },
    h2: {
      lineHeight: ['3', '6'],
      mb: ['1', '4'],
      fontSize: ['3', '6'],
      fontWeight: ['3'],
    },
    p: {
      pb: '6',
      fontSize: ['1', '2'],
      lineHeight: ['2', '4'],
    },
    h4: {
      fontSize: ['1', '2'],
    },
    '&.image-left': {
      marginBottom: '1.5rem',
      '@media only screen and (min-width: 600px)': {
        float: 'left',
        width: '40%',
        margin: '0 1.5rem 1.5rem 0',
      },
    },
    '&.image-right': {
      marginBottom: '1.5rem',
      '@media only screen and (min-width: 600px)': {
        float: 'right',
        width: '40%',
        margin: '0 0 1.5rem 1.5rem',
      },
    },
  },
});

// Template for producing all the pages in the blog category. Nice one

type Props = {
  data: {
    datoCmsArticle: {
      seoMetaTags: {
        id: string;
        children: any[];
        parent: any;
        tags: any[];
      };
      title: string;
      slug: string;
      content: string;
      noIndex?: boolean;
      featuredMedia: {
        alt: string;
        url: string;
        gatsbyImageData: {};
      };
      meta: {
        createdAt: string;
      };
      author: string;
      date: string;
      blogFooter: any[];
      originalId: string;
    };
    site: {
      siteMetadata: {
        siteUrl: string;
        title: string;
        description: string;
        author: string;
        image: string;
      };
    };
  };
};

const BlogPagesTemplate = ({ data }: Props) => {
  const {
    seoMetaTags,
    title,
    slug,
    content,
    featuredMedia,
    meta,
    author,
    date,
    blogFooter,
    originalId,
    noIndex,
  } = data.datoCmsArticle;

  // Add some JSON schema data to the top of a blog post to help with indexation/SEO
  const { siteMetadata } = data.site;
  const rootUrl = `${siteMetadata.siteUrl}`;
  const pagePath = `/blog/${slug}`;
  const postURL = rootUrl + pagePath;
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'BlogPosting',
      url: postURL,
      image: featuredMedia ? featuredMedia.url : '',
      headline: `${title}`,
      author: author ? author.name : 'SpotCast team',
      datePublished: `${meta.updatedAt}`,
      // logo: postURL, TODO logo here
      publisher: {
        '@type': 'Organization',
        name: siteMetadata.title,
      },
    },
  ];

  useEffect(() => {
    adjustVideoSize();
  }, []);

  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />
      <Helmet>
        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
        {noIndex && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>
      <AdminBar itemTypes="288623" originalId={originalId} />
      <Header
        title={title}
        subtitle={
          <BlogByLine
            mb="2.5rem"
            date={date || meta.createdAt}
            name={author ? author.name : 'SpotCast team'}
          />
        }
        byline
        image={false}
      />

      <Section mb={[4, 7]} pt={[2, 0]}>
        <Containers maxWidth="maxWidth" className="blog">
          <Container
            as="article"
            flex="0 1 727px"
            mb={['5', '2.5rem']}
            mr={['0', '32']}
            maxWidth="full"
          >
            <Box mb={['7']}>
              <BlogHomeLink to="/blog">&#8592; Blog Home</BlogHomeLink>
            </Box>

            {featuredMedia && (
              <Box mb={['7']}>
                <GatsbyImage
                  image={featuredMedia.gatsbyImageData}
                  alt={featuredMedia.alt}
                />
              </Box>
            )}

            <Body mb="8" dangerouslySetInnerHTML={{ __html: content }} />
            {blogFooter.map((block) => (
              <>
                {block.model.apiKey === 'call_to_action' && (
                  <div>
                    <Cta
                      key={block.id}
                      title={block.title}
                      buttonText={block.buttonText}
                      link={block.linkTo}
                    />
                  </div>
                )}
              </>
            ))}
            {/* <BlogSubscribe /> */}
          </Container>
          <Box as="aside" textAlign="left" flex="1 1 255px">
            <SubHeading
              pb="4"
              mb="4"
              sx={{
                borderBottom: '1px solid',
                borderBottomColor: '#25404e',
              }}
            >
              More Articles
            </SubHeading>

            {data.allDatoCmsArticle.edges.map(({ node }) => (
              <Link key={node.featuredMedia} to={`/blog/${node.slug}`}>
                <Box
                  mb={['4']}
                  sx={{
                    borderBottom: '1px solid',
                    borderBottomColor: '#25404e',
                  }}
                >
                  <SubHeading
                    sx={{
                      _hover: { textDecoration: 'underline' },
                    }}
                    mb={[1]}
                    dangerouslySetInnerHTML={{
                      __html: node.title,
                    }}
                  />
                  <BlogByLine
                    date={node.meta.createdAt}
                    fontSize="0"
                    mb="3"
                    mt="0"
                  />
                </Box>
              </Link>
            ))}
          </Box>
        </Containers>
      </Section>
    </>
  );
};

export default BlogPagesTemplate;

export const query = graphql`
  query BlogPageQuery($slug: String!) {
    datoCmsArticle(slug: { eq: $slug }) {
      id
      title
      content
      originalId
      categories {
        name
        slug
      }
      noIndex
      blogFooter {
        ... on DatoCmsCallToAction {
          model {
            apiKey
          }
          id
          title
          buttonText
          linkTo {
            ... on DatoCmsLandingPage {
              __typename
              slug
            }
            ... on DatoCmsFeaturePage {
              __typename
              slug
              treeParent {
                slug
                treeParent {
                  slug
                }
              }
            }
          }
        }
      }
      slug
      meta {
        createdAt
      }
      author {
        name
      }
      categories {
        name
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      featuredMedia {
        alt
        url
        gatsbyImageData(
          width: 1000
          layout: CONSTRAINED
          placeholder: BLURRED
          forceBlurhash: false
          imgixParams: { fm: "jpg", auto: "compress", w: "1000", dpr: 2 }
        )
      }
    }
    allDatoCmsArticle(limit: 5, filter: { locale: { eq: "en" } }) {
      edges {
        node {
          title
          slug
          author {
            name
          }
          meta {
            createdAt
          }
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
        title
        description
        author
        image
      }
    }
  }
`;
